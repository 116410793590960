import { Icon, Label, Paragraph } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { LuggageOptions } from '@AuroraTypes';
import { CabinBagTooltip } from '@Components/Checkout/LuggageOptions/CabinBagTooltip';
import { captureClientError } from '@Core/errors/errors';
import { useOrderStore } from '@Stores/checkout/CheckoutStoreContext';

export const LuggageDescription: React.FC<{
  luggageOptions: LuggageOptions;
}> = ({ luggageOptions }) => {
  const { cabinBagsDetails, mode, luggageDetails } = luggageOptions;

  const flight = useOrderStore((state) => state.getFlight());

  const { t } = useTranslation();
  const luggageDescription: JSX.Element[] = [];

  const isLuggageIncluded = mode === 'INCLUDED';

  let descriptionLabel;
  switch (mode) {
    case 'INCLUDED': {
      // We should never have luggageDetails undefined here - that only happens when mode NOT_AVAILABLE
      const { availableBags, weight } = luggageDetails ?? {};
      descriptionLabel = t('checkout.freeBaggageDescription', { availableBags, weight });
      break;
    }
    case 'NOT_AVAILABLE': {
      descriptionLabel = t('checkout.notIncludedBaggageDescription');
      break;
    }
    default:
      descriptionLabel = t('checkout.hugeSavings');
  }

  luggageDescription.push(<Paragraph variant="small">{descriptionLabel}</Paragraph>);

  if (!cabinBagsDetails) {
    const inboundCarriers = flight?.inbound
      .map(
        ({ operatingCarrierName, operatingCarrier }) =>
          `${operatingCarrierName} (${operatingCarrier})`,
      )
      .join(' -> ');

    const outboundCarriers = flight?.outbound
      .map(
        ({ operatingCarrierName, operatingCarrier }) =>
          `${operatingCarrierName} (${operatingCarrier})`,
      )
      .join(' -> ');

    captureClientError(
      `Missing cabin bags details for inbound flights: ${inboundCarriers} and outbound flights: ${outboundCarriers}`,
    );
  } else {
    const { underseatBagWeight, underseatBagDimensions } = cabinBagsDetails || {};

    luggageDescription.push(
      <Paragraph
        variant="small"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <span sx={{ paddingRight: '3xs' }}>
          {t('checkout.cabinBaggageDescription', {
            cabinBagAllowance: 1,
            cabinBagWeight: underseatBagWeight,
          })}
        </span>
        <CabinBagTooltip
          cabinBagWeight={underseatBagWeight}
          cabinBagDimensions={underseatBagDimensions}
        />
      </Paragraph>,
    );
  }

  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 'xs',
      }}
    >
      {isLuggageIncluded && (
        <Label
          variant="largebold"
          sx={{ color: 'textDimmedheavy' }}
        >
          {t('checkout.freeBaggageTitle')}
        </Label>
      )}

      {luggageDescription.map((description, index) => (
        <div
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Icon
            name="Markers/Confirmation"
            size="20"
            color="backgroundBlack"
            sx={{
              marginRight: '2xs',
            }}
          />
          <div>{description}</div>
        </div>
      ))}
    </div>
  );
};
