import { Skeleton } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment, forwardRef } from 'react';

import { LuggageDescription } from './LuggageDescription';
import { LuggageOptionsControls } from './LuggageOptionsControls';
import { LuggageOptions as LuggageOptionsType, ProductTrackingList } from '@AuroraTypes';
import { ContentCard } from '@Components/Checkout/ContentCard/ContentCard';
import { ContentCardSeparator } from '@Components/Checkout/ContentCard/ContentCardSeparator';
import { ContentTitle } from '@Components/Checkout/ContentTitle/ContentTitle';
import { useAppContext } from '@Contexts/contexts';
import { ProductType } from '@Core/tracking/types';
import { useOrderSelectionStore, useOrderStore } from '@Stores/checkout/CheckoutStoreContext';
import { useCheckoutProductImpressionTracking } from '@Tracking';

export enum LuggageControlType {
  ALL = 'ALL',
  INDIVIDUAL = 'INDIVIDUAL',
  NONE = 'NONE',
}

export interface LuggageOptionsProps {
  data: LuggageOptionsType;
  luggageControlType: LuggageControlType;
}

export const LuggageOptionsImpl = forwardRef<any, LuggageOptionsProps>(
  ({ data, luggageControlType }, ref) => {
    const { t } = useTranslation();

    return (
      <ContentCard
        data-id="luggage-content-card"
        ref={ref}
      >
        <div
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <ContentTitle
            mainIconName="Content/TravelCarryOn"
            headingTitle={t('luggage')}
          />
        </div>

        <LuggageDescription luggageOptions={data} />
        {luggageControlType !== 'NONE' && (
          <Fragment>
            <ContentCardSeparator
              sx={{
                marginX: [null, '-2xl'],
              }}
            />
            <LuggageOptionsControls luggageOptions={data} />
          </Fragment>
        )}
      </ContentCard>
    );
  },
);

export const LuggageOptions: React.FC = () => {
  const [luggageOptionsState, luggageOptions, shortRef, includedLuggageTypes, luggageControlType] =
    useOrderStore((state) => [
      state.luggageOptionsStatus,
      state.luggageOptions,
      state.order.shortRef,
      state.getIncludedLuggageTypes(),
      state.getLuggageControlType(),
    ]);
  const { site } = useAppContext();
  const [luggageSelection] = useOrderSelectionStore((state) => [state.luggageSelection]);

  const { mode, luggageDetails } = luggageOptions ?? {};
  const { weight, perParty, price = 0 } = luggageDetails ?? {};
  const selectedBags = luggageSelection.selectedBags;
  const luggagePriceTotal = price * selectedBags;
  const eventInfo = {
    mode,
    perParty,
    shortRef,
    selectedBags,
    weight,
    ...includedLuggageTypes,
    price: luggagePriceTotal,
    luggageControlType,
    list: 'Luggage' as ProductTrackingList,
    currencyCode: site.currency,
    category: ProductType.hotelAndFlight,
  };

  const ref = useCheckoutProductImpressionTracking(eventInfo, String(eventInfo.mode));

  if (luggageOptionsState === 'fetching') {
    return <Skeleton sx={{ height: 400, marginBottom: 'l' }} />;
  }

  if (luggageOptionsState === 'ok' && !!luggageOptions) {
    return (
      <LuggageOptionsImpl
        ref={ref}
        luggageControlType={luggageControlType}
        data={luggageOptions}
      />
    );
  }

  return null;
};
